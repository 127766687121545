import { Application } from '@clients/adventure/bean/application';
import { Coordinates } from '@clients/adventure/bean/coordinates';
import { Area } from '@models/area/area';
import { AreaType } from '@models/area/area.api';
import { ApplicationName } from '@models/context/application-name';
import { LocationType } from '@models/location/location';
import { CursusFilters } from './cursus-filters/cursus-filters';
import { ApiFilters } from './filters.api';
import { IFilters } from './filters.interface';
import { IndustryFilters } from './industry-filters/industry-filters';
import { LocalisationFilters } from './localisation-filters/localisation-filters';
import { SandwichFilters } from './sandwich-filters/sandwich-filters';
import { SituationFilters } from './situation-filters/situation-filters';

/**
 * Filters
 */
export class Filters implements IFilters {
  public isBridge = false;

  private nearByGeolocation(userGeolocation: GeolocationCoordinates): {
    latitude: number;
    longitude: number;
  } {
    return {
      latitude: userGeolocation.latitude,
      longitude: userGeolocation.longitude
    };
  }

  public get activeFilters(): number {
    return (
      this.localisationFilters.activeFilters +
      this.cursusFilters.activeFilters +
      this.situationFilters.activeFilters
    );
  }

  /**
   * Filters
   *
   * @param cursusFilters Cursus filters
   * @param industryFilters Industry filters
   * @param localisationFilters Localisation filters
   * @param situationFilters Situation filters
   * @param sandwichFilters Sandwich filters
   * @param limitNodes Number of waypoints expected by an expand for a domain
   * @param limitDomains Number of domains expected by an expand
   * @param skipDomains Number of domains to skip in the full domain list
   * @param limitDiplomaType
   * @param selectedDiplomaType
   * @param isInternship Number of domains to skip in the full domain list
   * @param trendsRegion Region where we must recover the trends
   * @param originUuid
   * @param useDiplomaType
   * @param locationPartnership
   * @param highSchoolType High school type filter
   * @param withVideo Ask humans with or without video
   * @param withWaypoints Ask waypoints in the humans results
   * @param ambassadorsOnly Only ambassadors in the humans results
   * @param skip Pagination filter skip
   * @param limit Pagination filter limit
   * @param skip Pagination filter skip
   * @param limit Pagination filter limit
   * @param minDiplomaLevel Low limit for diploma level filtering
   * @param maxDiplomaLevel High limit for diploma level filtering
   */
  constructor(
    public readonly cursusFilters = new CursusFilters(),
    public readonly industryFilters = new IndustryFilters(),
    public readonly localisationFilters = new LocalisationFilters(),
    public readonly situationFilters = new SituationFilters(),
    public readonly sandwichFilters = new SandwichFilters(),
    public limitNodes: number = null,
    public limitDomains: number = null,
    public skipDomains: number = null,
    public limitDiplomaType: number = null,
    public skipDiplomaType: number = null,
    public selectedDiplomaType: string = null,
    public isInternship = false,
    public isSandwichTraining = false,
    public trendsRegion: string = null,
    public originUuid: string = null,
    public useDiplomaType = false,
    public locationPartnership: LocationType = null,
    public highSchoolType: string = null,

    public withVideo = undefined,   // Dedicated to humans searches
    public withWaypoints = false,   // Dedicated to humans searches
    public isGenderFromUser = true, // Dedicated to humans searches
    public ambassadorsOnly = false, // Dedicated to humans searches

    public skip: number = null, // Generic
    public limit: number = null, // Generic (merge it with limitNodes?)
    public coordinates: Coordinates = null,

    public minDiplomaLevel = null, // Dedicated to diploma searches
    public maxDiplomaLevel = null // Dedicated to diploma searches
  ) {}

  /**
   * Get the Api Area object from the Area object (the temple of the shame ^^)
   *
   * @returns The corresponding Api Area object
   */
  public toApi(): ApiFilters {
    const apiFilters: ApiFilters = {};

    if (this.skip) {
      apiFilters.skip = this.skip;
    }

    if (this.limit) {
      apiFilters.limit = this.limit;
    }

    if (this.cursusFilters.isPublic) apiFilters.location_is_public = true;

    if (this.localisationFilters.area?.length > 0) {
      const regions = this.localisationFilters.area
        .filter(area => area.type === AreaType.region)
        .map(area => area.id);

      if (regions.length > 0) {
        apiFilters.location_regions = regions;
      }

      const countries = this.localisationFilters.area
        .filter(area => area.type === AreaType.country)
        .map(area => area.id);

      if (countries.length > 0) {
        apiFilters.location_countries = countries;
      }

      const near = this.localisationFilters.area
        .filter(area => area.type === AreaType.city)
        .map(area => this.nearByGeolocation(area.geolocation));

      if (near.length > 0) {
        apiFilters.near = near;
      }
    }

    if (this.industryFilters.industries?.length > 0) {
      apiFilters.location_industries = this.industryFilters.industries;
    }

    if (this.sandwichFilters.hasSandwichTrainings) {
      apiFilters.offer_is_sandwich_training = true;
    }

    if (this.localisationFilters.userGeolocation)
      apiFilters.near = [this.nearByGeolocation(this.localisationFilters.userGeolocation)];

    if (this.cursusFilters.free || this.cursusFilters.maxCost)
      apiFilters.max_cost = this.cursusFilters.free ? 0 : this.cursusFilters.maxCost;

    if (this.cursusFilters.requiredExams.length > 0) {
      if (this.cursusFilters.requiredExams[0] === CursusFilters.NO_EXAMS)
        apiFilters.application_offer = false;
      else apiFilters.application_offers = this.cursusFilters.requiredExams;
    }

    if (this.situationFilters) apiFilters.situationFilters = this.situationFilters;

    if (this.situationFilters.useSituation) {
      const { status, bacSpecialties, highSchoolType, highSchoolClass } =
        this.situationFilters.situation;

      apiFilters.use_situation = true;

      apiFilters.status = status;

      if (bacSpecialties.length) {
        apiFilters.bac_specialities = bacSpecialties;
      }

      if (highSchoolType) {
        apiFilters.highSchoolType = highSchoolType;
      }

      if (highSchoolClass) {
        apiFilters.high_school_class = highSchoolClass;
      }
    }

    if (this.locationPartnership) {
      apiFilters.locationPartnership = this.locationPartnership;
    }

    if (this.limitNodes != null) apiFilters.limit_nodes = this.limitNodes;

    if (this.limitDomains != null) apiFilters.limit_domains = this.limitDomains;

    if (this.skipDomains != null) apiFilters.skip_domains = this.skipDomains;

    if (this.limitDiplomaType != null) apiFilters.limit_diploma_type = this.limitDiplomaType;

    if (this.skipDiplomaType != null) apiFilters.skip_diploma_type = this.skipDiplomaType;

    if (this.selectedDiplomaType != null)
      apiFilters.selected_diploma_type = this.selectedDiplomaType;

    if (this.useDiplomaType || !!this.selectedDiplomaType)
      apiFilters.use_diploma_type = this.useDiplomaType || !!this.selectedDiplomaType;

    if (this.isInternship) {
      apiFilters.isInternship = this.isInternship;
    }

    if (this.isSandwichTraining) {
      apiFilters.isSandwichTraining = this.isSandwichTraining;
    }

    if (this.trendsRegion) apiFilters.trends_region = this.trendsRegion;

    if (this.originUuid) apiFilters.origin_uuid = this.originUuid;

    if (this.isBridge) apiFilters.isBridge = this.isBridge;

    if (this.withVideo !== undefined) { // Optional parameter, true, false, undefined
      apiFilters.withVideo = this.withVideo;
    }

    if (this.withWaypoints) {
      apiFilters.withWaypoints = this.withWaypoints;
    }

    if (!this.isGenderFromUser) {
      apiFilters.isGenderFromUser = this.isGenderFromUser;
    }

    if (this.highSchoolType) {
      apiFilters.highSchoolType = this.highSchoolType;
    }

    if (this.ambassadorsOnly) {
      apiFilters.ambassadorsOnly = this.ambassadorsOnly;
    }

    if (this.coordinates) {
      apiFilters.coordinates = this.coordinates;
    }

    if (this.minDiplomaLevel !== null) {
      apiFilters.min_diploma_level = this.minDiplomaLevel;
    }

    if (this.maxDiplomaLevel !== null) {
      apiFilters.max_diploma_level = this.maxDiplomaLevel;
    }

    return apiFilters;
  }

  public empty(): void {
    this.cursusFilters.empty();
    this.industryFilters.empty();
    this.localisationFilters.empty();
    this.situationFilters.empty();
    this.sandwichFilters.empty();
    this.locationPartnership = null;
  }

  public context(application: Application): Filters {
    if (application.application === ApplicationName.RegionSud) {
      const paca = new Area(
        'region-provence-alpes-cote-d-azur-93-fr',
        'Région Sud',
        AreaType.region
      );

      this.localisationFilters.area.push(paca);
    }

    return this;
  }
}

export enum ApplicationName {
  Ademe = 'ademe',
  Carto = 'carto',
  CmqBtpNumerique = 'cmq-btp-numerique',
  Dispatch = 'dispatch',
  MyRoad = 'parcoursup',
  PaysDeLaLoire = 'pays-de-la-loire',
  Oraccle = 'oraccle',
  RegionSud = 'region-sud',
  Popup = 'popup'
}
